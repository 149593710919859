import '../styles/DemoMain.css'
import logo_coin from '../images/main/logos_svg/logocoin.svg'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../styles/Main.css'


function DemoMain() {
    const [enlargedIndex, setEnlargedIndex] = useState(1);
    
    return (
          <div className='demo_MainBack'>
            <Link to='/demoCoinFlip' >
            <img src={logo_coin} className={enlargedIndex === 1 ? 'enlarged pulsating-button' : 'noenlarged'}></img>
            </Link>
          </div>
    );
  }
  
  export default DemoMain;