import { useContext, useEffect, useState } from 'react';
import '../styles/Skulls.css';
import button_mix from '../images/skulls/Button.svg'
import start_ru from '../images/skulls/startru.gif'
import start_en from '../images/skulls/starten.gif'
import left from '../images/skulls/left.svg'
import center from '../images/skulls/center.svg'
import right from '../images/skulls/right.svg'
import { Link } from 'react-router-dom';
import { Context } from '../context';
import { languages } from '../language';




function Skulls() {
  const {lang,setlang} = useContext(Context)
  const data=languages[lang]
  
  

  const list_gif =[
    {img:require('../images/skulls/center.gif'),text:"center"},
    {img:require('../images/skulls/left.gif'),text:"left"},
    {img:require('../images/skulls/right.gif'),text:"right"},
  ]


  const [showResult,setShowResult] =useState(false)
  const [randomImage, setRandomImage] = useState(null);
  const [inputText, setInputText] = useState('');
  const [showStart,setShowStart]= useState(true);//
  const [randomIndex,setRandomIndex]=useState(false)//
  const [res,setRes]= useState();
  
  useEffect(() => {
    if (randomIndex !== null) {
    }
  }, [randomIndex]);

  useEffect(() => {
    if (randomImage !== null) {
      setRes(list_gif[randomIndex].text);
    }
  }, [randomImage, list_gif, randomIndex, res]);


  const pushButton = () => {
    if (inputText.trim() === '') return;
    setShowResult(false);
    setShowResult(true);
    setShowStart(false);
    const randomIndex = Math.floor(Math.random() * list_gif.length);
    setRandomIndex(randomIndex);
    setRandomImage(list_gif[randomIndex], () => {
      setRes(list_gif[randomIndex].text)
    });
    setInputText('');
    setTimeout(() => {
    setShowResult(false);
  }, 4400);
  };


  const gradientStyle = {
    background: 'linear-gradient(to right, #F4D04B, #D46D50)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none'
};

//{showStart ? <div className='div_result'><img src={start_ru} className='img_result' /></div> : null}


  return (    
    <div className='div_background'>
        <div className='div_result'>
        
        {res==='left'&& <span className='res_text'> {data.left_skulls} </span>}
        {res==='center'&& <span className='res_text'> {data.center_skulls} </span>}
        {res==='right'&& <span className='res_text'> {data.right_skulls} </span>}

        {showResult && randomIndex != null && <img src={list_gif[randomIndex].img} className='img_result' />}

        

        {showStart && data.hash_of_the_round==="Введите хеш рауда" && <div className='div_result'><img src={start_ru} className='img_result' /></div>}
        {showStart && data.hash_of_the_round==="Enter the hash of the round" && <div className='div_result'><img src={start_en} className='img_result' /></div>}

        {!showResult && randomImage && randomImage.text==='center' && <img src={center} className='img_result'/>}
        { !showResult && randomImage && randomImage.text==='left' && <img src={left} className='img_result'/>}
        { !showResult && randomImage && randomImage.text==='right' && <img src={right} className='img_result'/>}
      </div>

      
      <input
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder={data.hash_of_the_round}
      />

      <div onClick={pushButton}>
        <img src={button_mix} className='button_mix'></img>
      </div>

      <Link to="/main" className="menu" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate'>↺</span>
            <span style={gradientStyle} > {data.menu} </span>
      </Link>
    </div>
  );
}
  export default Skulls;
  