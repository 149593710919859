import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './MinesGames_style.css'; // Добавьте ваши стили


const gridSize = 5; // Размер сетки (5x5)

function MinesGame() {
    const [cells, setCells] = useState(Array(gridSize * gridSize).fill(null)); // Массив ячеек
    const [flippedCells, setFlippedCells] = useState([]); // Перевернутые ячейки
    const [quantityMines, setQuantityMines] = useState(3); // Количество мин (для звезд)
    
    // Функция для переворота ячеек с задержкой
    const flipRandomCells = () => {
        const numStars = quantityMines === 3 ? 7 : quantityMines === 5 ? 5 : 3;
        const flipped = [];
        
        // Открываем ячейки по одной с задержкой
        for (let i = 0; i < numStars; i++) {
            setTimeout(() => {
                const randomCell = Math.floor(Math.random() * cells.length);
                if (!flipped.includes(randomCell)) {
                    flipped.push(randomCell);
                    setFlippedCells((prevFlipped) => [...prevFlipped, randomCell]);
                }
            }, i * 500); // Открываем каждые 500мс
        }
    };

    // Функция для закрытия ячеек перед новым открытием
    const resetFlippedCells = () => {
        setFlippedCells([]);
    };

    return (
        <div className="mines-container">
            <div className="grid">
                {cells.map((cell, index) => (
                    <div
                        key={index}
                        className={`cell ${flippedCells.includes(index) ? 'flipped' : ''}`}
                    >
                        <div className="cell-content">
                            <div className="front">
                                <div className="square"></div>
                            </div>
                            <div className="back">
                                <div className="star">⭐</div> {/* Анимация появления звезды */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button
                onClick={() => {
                    resetFlippedCells(); // Сначала закрываем ячейки
                    setTimeout(flipRandomCells, 300); // Затем через 300 мс открываем новые
                }}
                className="signal-btn"
            >
                Получить сигнал
            </button>
        </div>
    );
}

export default MinesGame;