import { BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./components/Main";
import CoinFlip from "./components/CoinFlip";
import LackyJet from "./components/LackyJet";
import Mines from "./components/Mines";
import DemoMain from "./components/DemoMain";
import DemoCoinFlip from "./components/DemoCoinFlip";
import { Context } from "./context";
import { useState } from "react";
import Skulls from "./components/Skulls";
import Bombacks from "./components/Bombacks";
import Crash from "./components/Crash";
import Coefficients from "./components/Сoefficients"
import Test from "./components/Test";
import MinesGame from "./components/MinesGames";


function App() {
  const [lang,setlang]=useState('ru')
  
  return (
    <div style={{ height: '100%' }}>
      <Context.Provider value={{lang,setlang}}>
        <BrowserRouter>
          <Routes>
          <Route path="/minesgame" element={<MinesGame />} />
          <Route path="/test" element={<Test />} />
          <Route path="/bombacks" element={<Bombacks />} />
            <Route path="/main" element={<Main />} />
            <Route path="/coinflip" element={<CoinFlip />} />
            <Route path="/lackyjet" element={<LackyJet />} />
            <Route path="/mines" element={<Mines />} />
            <Route path="/demoMain" element={<DemoMain  />} />
            <Route path="/demoCoinFlip" element={<DemoCoinFlip />} />
            <Route path="/skulls" element={<Skulls />} />
            <Route path="/crash" element={<Crash />} />
            <Route path="/coefficients" element={<Coefficients />} />
            <Route path="/" element={<Main  />} /> {/* При точном совпадении пути "/" отображаем компонент Main */}
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </div>
  );
}

export default App;
