//import axios from "axios";
import loading from '../images/loading.gif'
import rocket from '../images/rocketGif.svg'
import '../styles/Сoefficients.css'
import { useEffect, useState } from 'react';
import WebSocketClient from './WebSocketClient';
import axios from 'axios';

function getColor(coefficient) {
    // Преобразуем коэффициент в число, удаляя суффикс 'x'
    const value = parseFloat(coefficient.replace('x', '').replace(/\s+/g, ''));
    if (value > 10) return '#DE8B03';
    if (value > 2) return '#753EC2';
    return '#3E5BC2';
}

function Coefficients() {
    const [coefficients, setCoefficients] = useState([]);
    const [error, setError] = useState(null);
    const [animate, setAnimate] = useState(false);
    const [showGifRocket, setShowGifRocket] = useState(false);

    const handleMessage = (newCoefficients) => {
        //console.log(coefficients)
    
        //console.log(newCoefficients[0]);
        if(newCoefficients[0]==='Error occurred while fetching data'){
            return
        }

        if(newCoefficients[0]==='Duplicate data, please try again later'){
            return
        }

        if(newCoefficients[0]==='First coefficient is empty, previous coefficients not updated'){
            return
        }

        setAnimate(true);
        setShowGifRocket(true);
        
       // console.log(newCoefficients[0]+'dnjhjt');
    
        setTimeout(() => {
            // Обновляем все 5 коэффициентов
            setCoefficients(prevCoefficients => {
                // Если получено больше 5 коэффициентов, используем только первые 5
                const updatedCoefficients = newCoefficients.slice(0, 5);
                return updatedCoefficients;
            });
    
            setAnimate(false);
        }, 500); // Длительность анимации в миллисекундах
    
        setTimeout(() => {
            setShowGifRocket(false);
        }, 10000); // Длительность отображения GIF в миллисекундах
    };

    return (
        <div className="background">
            {coefficients.length==0 && <p style={{ color: 'white', fontSize: '20px' }}>Ожидаем конец раунда</p>}

            {/* Используем WebSocketClient */}
            <WebSocketClient onMessage={handleMessage} />

            {/* Отображаем коэффициенты */}
            <div className={`coefficients-container ${animate ? 'animate' : ''}`}>
                {coefficients.map((coef, index) => (
                    <div
                        key={index}
                        className="coefficient-item"
                        style={{
                            margin: '5px',
                            padding: '10px',
                            backgroundColor: getColor(coef),
                            color: 'white',
                            borderRadius: '5px',
                            textAlign: 'center',
                            minWidth: '40px',
                            minHeight: '15px',
                        }}
                    >
                        {coef || 'N/A'}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Coefficients;