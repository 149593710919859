import logo from '../images/main/logos_svg/logocoin.svg';
import button from '../images/buttoncoin.svg'
import '../styles/CoinFlip_style.css'
import React, { useContext, useEffect, useState } from 'react';
import animation from '../images/animation.gif'
import one from '../images/1.png'
import two from '../images/2.png'
import { Link } from 'react-router-dom';
import { Context } from '../context';
import { languages } from '../language';
import backButton from '../images/underbutton.gif'

function CoinFlip() {
  const [showAnimation, setShowAnimation] = useState(false);
  const [currentImage, setCurrentImage] = useState(one);
  const [result,setResult] = useState('')
  const {lang,setlang} = useContext(Context)
  const data=languages[lang]


  const flipCoin = () => {
    // Показываем анимацию
    setShowAnimation(true);

    setTimeout(() => {
      // Генерируем случайное число от 0 до 1
      const random = Math.random();
      // Если число меньше 0.5, устанавливаем первое изображение, иначе - второе
      const newImage = random < 0.5 ? one : two;
      if(newImage === one ){
        setResult(data.reshka)
      }
      else{
       // setResult('ОРЕЛ')
        setResult(data.orel)
      }
      setCurrentImage(newImage);
      // Скрываем анимацию
      setShowAnimation(false);
    }, 2000); // Задержка в миллисекундах перед сменой изображения (в данном случае 1 секунда)
  };

  const gradientStyle = {
    background: 'linear-gradient(to right, #F4D04B, #D46D50)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    
};
  return (
        <div className="CoinFlipBack">
          <img src={logo} className='logo_coinflip'></img>
        {/* Если showAnimation равен true, отображаем анимацию, иначе - текущее изображение */}
        {showAnimation ? (
          <img src={animation} alt="Animation" className='animation'/>
        ) : (

            <img src={currentImage} alt="Coin" className='coinFlip'/>
 
        )}
        
        
          <span className="resultFlip" >{result}</span>
        


        
        <img src={button} onClick={flipCoin} className='buttonFlip'></img> 
        

        <Link to="/main" className="menu" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate'>↺</span>
            <span style={gradientStyle} > {data.menu} </span>
        </Link>
        
      </div>
  );
}

export default CoinFlip;