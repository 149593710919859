import React, { useContext, useState } from 'react';
import full_black from '../images/bombacks/static/1.png'
import '../styles/Bombacks.css'
import { Link } from 'react-router-dom';
import { Context } from '../context';
import { languages } from '../language';
import signal from '../images/lacky/buttonlucky.svg'

const randomImages = [
    { img: require('../images/bombacks/static/1.1.png'), text: "left", gif_out: require('../images/bombacks/1.1out.gif'), gif_start: require('../images/bombacks/1.1start.gif') },
    { img: require('../images/bombacks/static/1.2.png'), text: "center", gif_out: require('../images/bombacks/1.2out.gif'), gif_start: require('../images/bombacks/1.2start.gif') },
    { img: require('../images/bombacks/static/1.3.png'), text: "right", gif_out: require('../images/bombacks/1.3out.gif'), gif_start: require('../images/bombacks/1.3start.gif') }
  ];
  
  function Bombacks() {
    const [currentImage, setCurrentImage] = useState(full_black);
    const [isPlaying, setIsPlaying] = useState(false);
    const [buttonText, setButtonText] = useState('Начать');
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * randomImages.length));
    const {lang,setlang} = useContext(Context)
    const data=languages[lang]
    const [counter,setCounter]=useState(0)
  
    const handleClick = () => {
      setCounter(counter+1)
      setIsPlaying(true);
      const savedIndex = currentIndex; // сохраняем текущий индекс
  
      if (buttonText === 'Начать') {
        setCurrentImage(randomImages[savedIndex].gif_start);
        console.log(randomImages[savedIndex].gif_start + " первый вызов");
  
        setTimeout(() => {
          console.log(savedIndex);
          setCurrentImage(randomImages[savedIndex].img);
          console.log(savedIndex);
          console.log(randomImages[savedIndex].img + " второй вызов");
          console.log(savedIndex);
          setButtonText('Далее');
          setIsPlaying(false);
        }, 1000);
      } else {
        setCurrentImage(randomImages[savedIndex].gif_out);
        //console.log(randomImages[savedIndex].gif_out + " первый вызов");
  
        setTimeout(() => {
          //console.log(savedIndex);
          const newRandomIndex = Math.floor(Math.random() * randomImages.length);
          setCurrentIndex(newRandomIndex);
          setCurrentImage(randomImages[newRandomIndex].img);
          //console.log(randomImages[newRandomIndex].img + " второй вызов");
          setButtonText('Далее');
          setIsPlaying(false);
        }, 1000);
      }
    };


    const gradientStyle = {
      background: 'linear-gradient(to right, #F4D04B, #D46D50)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textDecoration: 'none'
  };

  /*
            <button onClick={handleClick} className="gradient-button">
            {buttonText}
          </button>
  */
  
    return (
      <div className="div_content">
        <div className="main">
          <span className='text' >{data.step} {counter}</span>
          <img src={currentImage} className="result" alt="current" />
          <img src={signal} alt="Signal" onClick={handleClick} style={{width:200}}/>
        </div>
        <Link to="/main" className="menu_jet" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate'>↺</span>
            <span style={gradientStyle} > {data.menu} </span>
        </Link>
      </div>
    );
  }
  
  export default Bombacks;