import { useEffect, useState } from 'react';
import { Client, Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

/*
const WebSocketClient = ({ onMessage }) => {
    useEffect(() => {
        const client = new Client({
            //brokerURL: 'ws://localhost:8080/ws',
            //brokerURL: 'ws://fartjet.store/ws',
            connectHeaders: {},
            debug: function (str) {
                //console.log('STOMP: ' + str);
            },
            onConnect: () => {
                client.subscribe('/topic/coefficients', (message) => {
                    try {
                        const data = JSON.parse(message.body);
                        if (Array.isArray(data)) {
                            onMessage(data);
                        } else {
                            console.error('Received data is not an array:', data);
                        }
                    } catch (error) {
                        console.error('Failed to parse message body:', message.body, error);
                    }
                });
            },
            onStompError: (frame) => {
                console.error('STOMP error', frame);
            },
            //webSocketFactory: () => new SockJS('http://localhost:8080/ws'),
            webSocketFactory: () => new SockJS('https://fartjet.store/ws'),
            //https://fartjet.store/

        });

        client.activate();

        return () => {
            client.deactivate();
        };
    }, [onMessage]);

    return null;
};

export default WebSocketClient;
*/



/*
const WebSocketClient = ({ onMessage }) => {
    useEffect(() => {
        const client = new Client({
            connectHeaders: {},
            debug: function (str) {
                console.log('STOMP: ' + str);
            },
            onConnect: () => {
                console.log('Connected to WebSocket');
                client.subscribe('/topic/coefficients', (message) => {
                    try {
                        const data = JSON.parse(message.body);
                        if (Array.isArray(data)) {
                            onMessage(data);
                        } else {
                            console.error('Received data is not an array:', data);
                        }
                    } catch (error) {
                        console.error('Failed to parse message body:', message.body, error);
                    }
                });
            },
            onStompError: (frame) => {
                console.error('STOMP error', frame);
            },
            webSocketFactory: () => new SockJS('https://fartjet.store/ws'),
            reconnectDelay: 5000, // Попробовать переподключение через 5 секунд
        });

        client.activate();

        return () => {
            client.deactivate();
            console.log('WebSocket connection closed');
        };
    }, [onMessage]);

    return null;
};

export default WebSocketClient;
*/



const WebSocketClient = ({ onMessage }) => {
    useEffect(() => {
        const client = new Client({
            connectHeaders: {},
            debug: function (str) {
                //console.log('STOMP: ' + str);
            },
            onConnect: () => {
                //console.log('Connected to WebSocket');
                client.subscribe('/topic/coefficients', (message) => {
                    try {
                        const data = JSON.parse(message.body);
                        if (Array.isArray(data)) {
                            onMessage(data);
                        } else {
                            //console.error('Received data is not an array:', data);
                        }
                    } catch (error) {
                        //console.error('Failed to parse message body:', message.body, error);
                    }
                });
            },
            onStompError: (frame) => {
                //console.error('STOMP error', frame);
            },
            webSocketFactory: () => new SockJS('https://fartjet.store/ws'), // Используйте https://
            reconnectDelay: 5000, // Попробовать переподключение через 5 секунд
        });

        client.activate();

        return () => {
            client.deactivate();
            //console.log('WebSocket connection closed');
        };
    }, [onMessage]);

    return null;
};

export default WebSocketClient;