import { Link } from "react-router-dom";
import CoinFlip from "./CoinFlip";
import coin from '../images/main/logoNEWCOIN.png'
import lacky from '../images/main/luckyUP.png'
import '../styles/Main.css'
import logo from '../images/main/logo.svg'
import mines from '../images/main/mines.png'
import ru from '../images/main/ru.png'
import en from '../images/main/us.png'
import spain from '../images/main/spain.png'
import { useContext, useEffect, useState } from "react";
import { Context } from "../context";
import coin_svg from '../images/main/logos_svg/logocoin.svg'
import lacky_svg from '../images/main/logos_svg/logolucky.svg'
import mines_svg from '../images/main/logos_svg/logomines.svg'
import skulls_svg from '../images/main/logos_svg/logoskulls.svg'
import bombacks_svg from '../images/main/logos_svg/logobacks.svg'
import crash_svg from '../images/crash/logocrash.svg'

function Main() {
    const {lang,setlang} = useContext(Context)
    const [enlargedIndex, setEnlargedIndex] = useState(-1); // Индекс текущего увеличенного изображения
    //const images = [coin, lacky, mines]; // Массив изображений
    const images = [1, 2, 3,4,5];
  
    useEffect(() => {
      const timer = setInterval(() => {
        // Устанавливаем следующий индекс для увеличенного изображения
        setEnlargedIndex(prevIndex => (prevIndex + 1) % images.length);
      }, 2000); // Интервал в 5 секунд (5000 миллисекунд)
  
      return () => clearInterval(timer); // Очищаем таймер при размонтировании компонента
    }, [images.length]); // Зависимость от длины массива изображений
  /*
          <Link to="/coinflip">
            <img src={coin_svg} className={enlargedIndex === 0 ? 'enlarged' : 'noenlarged'} />
        </Link>
  */

  return (

    <div className="mainModel">
        <div className="langdiv">
            <img src={ru} onClick={()=>setlang('ru')} className="lang" tabIndex="0"></img>
            <img src={en} onClick={()=>setlang('en')} className="lang" tabIndex="0"></img>
            <img src={spain} onClick={()=>setlang('spain')} className="lang" tabIndex="0"></img>
        </div>   
      <img src={logo} className="logo" ></img>

      <Link to="/crash">
          <img src={crash_svg} className={enlargedIndex === 0 ? 'enlarged' : 'noenlarged'} ></img>
      </Link>

      <Link to="/lackyjet">
          <img src={lacky_svg} className={enlargedIndex === 1 ? 'enlarged' : 'noenlarged'} ></img>
      </Link>
      <Link to="/mines">
          <img src={mines_svg} className={enlargedIndex === 2 ? 'enlarged' : 'noenlarged'} ></img>
      </Link>
      <Link to="/skulls">
          <img src={skulls_svg} className={enlargedIndex === 3 ? 'enlarged' : 'noenlarged'} ></img>
      </Link>

      <Link to="/bombacks">
          <img src={bombacks_svg} className={enlargedIndex === 4 ? 'enlarged' : 'noenlarged'} ></img>
      </Link>
    </div>
  );
}

export default Main;